import styled from 'styled-components';
import { device } from '../Breakpoints';

export const SplashStyles = styled.section`
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    position: sticky;
    top: 0;
    overflow: hidden;
    .splash-image {
        &__wrapper {
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            &--desktop {
                @media ${device.$small} {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                @media ${device.$small} {
                    display: block;
                    height: 100vh;
                }
            }
        }
        &__logo {
            width: 75%;
        }
    }
    .splash-logo {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bg-vid__container {
        position: relative;
        height: 100%;
        width: 100%;
        &--desktop {
            @media ${device.$small} {
                display: none;
            }
        }
        &--mobile {
            display: none;
            @media ${device.$small} {
                display: block;
            }
        }
        video,
        mux-player {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    mux-player {
        --controls: none;
        --media-object-fit: cover;
        --media-object-position: center;
    }
`

export const StaticImageBackground = styled.section`
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .background-image-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .splash-image {
            &__wrapper {
                max-width: 100%;
                max-height: 100%;
                height: 100%;
                &--desktop {
                    @media ${device.$small} {
                        display: none;
                    }
                }
                &--mobile {
                    display: none;
                    @media ${device.$small} {
                        display: block;
                        height: 100vh;
                    }
                }
            }
            &__logo {
                width: 75%;
            }
        }
    .splash-logo {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    .bg-vid__container {
        position: relative;
        height: 100%;
        width: 100%;
        &--desktop {
            @media ${device.$small} {
                display: none;
            }
        }
        &--mobile {
            display: none;
            height: 100%;
            @media ${device.$small} {
                display: block;
            }
        }
        video {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
`