import React from 'react'
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

import HeaderAndMenu from '../components/HeaderAndMenu';
import MyMarquee from '../components/Marquee'
import Cursor from '../components/Cursor';

import IndexSplash from '../components/IndexSplash'
import IndexFeatured from '../components/IndexFeatured';
import IndexVideoBanner from '../components/IndexVideoBanner';
import IndexShop from '../components/IndexShop';
import IndexDiary from '../components/IndexDiary';
import IndexSecondaryMenu from '../components/IndexSecondaryMenu';
import Footer from '../components/Footer';

const IndexStyles = styled.div`
      .mailto-holder {
        position: sticky;
        bottom: 48px;
        margin-left: auto;
        margin-right: 48px;
        z-index: 14;
        width: fit-content;
        @media ${device.$small} {
            width: 100vw;
        }
        a {
          display: block;
        }
        .internal-holder {
          position: relative;
          .close-holder {
            position: absolute;
            right: 16px;
            top: 16px;
            font-size: 12px;
            font-weight: normal;
            text-transform: lowercase;
            cursor: pointer;
            &:hover {
              background: -webkit-linear-gradient(1turn, #EE82EE, #4B0082, #16CAD6);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;  
            }
          }
        }
      }
    `

    
export default function HomePage({ data }) {
    // console.log('DATA', data)

    // const testJSON = JSON.parse(data.galleryData.nodes[2].credits[0]._rawChildren[0].text)

    // console.log('JSON', testJSON.test)

    

    const allSectionData = data.homepageData.nodes[0].content;
    const siteSettings = data.siteSettings.nodes[0];
    const imageData = data.imageData.nodes;

    const splashData = allSectionData.find(sectionData => {
        return sectionData.__typename.includes("SanityIndexSectionSplash")
    })

    const showSplashSection = splashData?.enable_section && splashData?.desktop_image ? true : false;

    // console.log('splash data', splashData)

    const sectionsToDisplay = [];
    allSectionData.forEach(sectionData => {
        if (sectionData.__typename.includes("SanityIndexSectionFeatured") && sectionData.enable_section) {
            sectionsToDisplay.push(<IndexFeatured key={sectionData._key} galleries={sectionData.featured_galleries} galleryHoverGif={siteSettings.gallery_hover_gif} siteSettings={siteSettings}/>)
        }
        if (sectionData.__typename.includes("SanityIndexSectionVideoBanner") && sectionData.enable_section) {
          sectionsToDisplay.push(<IndexVideoBanner key={sectionData._key} videoBannerData={sectionData}/>)
        }
        if (sectionData.__typename.includes("SanityIndexSectionDiary") && sectionData.enable_section) {
          sectionsToDisplay.push(<IndexDiary key={sectionData.__typename} diarySectionData={sectionData} imageData={imageData} />)
        }
        if (sectionData.__typename.includes("SanityIndexSectionShop") && sectionData.enable_section) {
          sectionsToDisplay.push(<IndexShop key={sectionData.__typename} shopData={sectionData}/>)
        }
    });

    // Insert secondary menu before video banner
    sectionsToDisplay.splice(1, 0, <IndexSecondaryMenu siteSettings={data.siteSettings.nodes[0]} key={'secondary-menu'} />)


    const closeMailto = (event) => {
      event.currentTarget.parentNode.parentNode.style.display = 'none';
    }

    return (
      <>
        <Helmet>
          <title>Imogen Wilson</title>
          <script src="https://cdn.jsdelivr.net/npm/@mux/mux-player"></script>
        </Helmet>
        <IndexStyles id="index-container">
            <Cursor 
              staticText={data.siteSettings.nodes[0].cursor_static}
              staticTextColor={data.siteSettings.nodes[0].cursor_static_color.hex}
              cursorSparkle={data.siteSettings.nodes[0].cursor_sparkle}
            />
            {
              showSplashSection &&
              <IndexSplash key={splashData.__typename} data={splashData} logo={ siteSettings.logo }/>
            }
            <main>
              <HeaderAndMenu siteSettings={data.siteSettings.nodes[0]} roleData={data.allSanityRole.nodes} page={'index'} />
              <MyMarquee color={data.siteSettings.nodes[0].marquee_colour?.rgb} content={data.siteSettings.nodes[0].marquee_text}/>
                { sectionsToDisplay }
                {
                  data.siteSettings.nodes[0].mailto_image &&
                  <div className="mailto-holder">
                    <div className="internal-holder">
                      <div className="close-holder" onClick={closeMailto}>Close</div>
                      <a href="mailto:immy@imogenwilson.com">
                        <img src={data.siteSettings.nodes[0].mailto_image.asset.fluid.src} alt="contact box" />
                      </a>
                    </div>
                  </div>
                }
            </main>
            <Footer siteSettings={data.siteSettings.nodes[0]} />
        </IndexStyles>
      </>
    )
}

export const query = graphql`
query {
    homepageData: allSanityHomepage {
        nodes {
          content {
            ... on SanityIndexSectionSplash {
                enable_section
                desktop_image {
                    asset {
                        fluid(maxWidth: 720) {
                            ...GatsbySanityImageFluid_noBase64
                        }
                    }
                }
                desktop_video {
                  mux_video {
                    asset {
                      playbackId
                    }
                  }
                  video_file {
                    asset {
                      url
                      mimeType
                    }
                  }
                }
                mobile_video {
                  video_file {
                    asset {
                      url
                      mimeType
                    }
                  }
                }
                enable_desktop_video
                enable_mobile_video
                mobile_image {
                    asset {
                      fluid(maxWidth: 600) {
                        ...GatsbySanityImageFluid_noBase64
                        }
                    }
                }
                splash_logo {
                    asset {
                        fluid(maxWidth: 1800) {
                            ...GatsbySanityImageFluid_noBase64
                        }
                    }
                }
              }
              ... on SanityIndexSectionShop {
                enable_section
                products {
                  product_id
                }
              }
              ... on SanityIndexSectionFeatured {
                _key
                enable_section
                featured_galleries {
                  name
                  role {
                    name
                  }
                  images {
                    asset {
                      fluid(maxWidth: 350) {
                        ...GatsbySanityImageFluid_noBase64
                      }
                    }
                  }
                  cover_image {
                    asset {
                      fluid(maxWidth: 350) {
                        ...GatsbySanityImageFluid_noBase64
                      }
                    }
                  }
                  enable_cover_video
                  cover_video {
                    mux_video {
                      asset {
                        playbackId
                      }
                    }
                    video_file {
                      asset {
                        url
                        mimeType
                      }
                    }
                  }
                  cover_video_vimeo
                  slug {
                    current
                  }
                  credits {
                    _key
                    _type
                    style
                    list
                    _rawChildren
                  }
                  vimeo_url
                }
              }
              ... on SanityIndexSectionVideoBanner {
                _key
                desktop_video {
                  mux_video {
                    asset {
                      playbackId
                    }
                  }
                  video_file {
                    asset {
                      url
                      mimeType
                    }
                  }
                }
                mobile_video {
                  video_file {
                    asset {
                      url
                      mimeType
                    }
                  }
                }
                enable_section
                enable_mobile_video
                desktop_vimeo_url
                mobile_vimeo_url
              }
              ... on SanityIndexSectionDiary {
                diary_json
                enable_section
              }
          }
          id
        }
    }
    galleryData: allSanityGallery {
        nodes {
          name
          role {
            name
          }
          images {
            asset {
              fluid(maxWidth: 350) {
                ...GatsbySanityImageFluid_noBase64
                src
              }
            }
          }
          cover_image {
            asset {
              fluid(maxWidth: 350) {
                ...GatsbySanityImageFluid_noBase64
                src
              }
            }
          }
          enable_cover_video
          cover_video {
            video_file {
              asset {
                url
                mimeType
              }
            }
          }
          cover_video_vimeo
          slug {
            current
          }
          credits {
            _key
            _type
            style
            list
            _rawChildren
          }
          vimeo_url
        }
    }
    siteSettings: allSanitySiteSettings {
        nodes {
          logo {
            asset {
              fluid(maxWidth: 1800) {
                ...GatsbySanityImageFluid_noBase64
              }
              extension
              url
            }
          }
          menu_colour {
            hex
          }
          menu_text_colour {
            hex
          }
          menu_emoji_one
          menu_emoji_one_url
          menu_emoji_two
          menu_emoji_two_url
          menu_emoji_three
          menu_emoji_three_url
          marquee_colour {
            rgb {
              a
              b
              g
              r
            }
          }
          marquee_text
          cursor_static
          cursor_sparkle
          cursor_static_color {
            hex
          }
          mimi_casting_url
          instagram_url
          shop_url
          vimeo_url
          footer_mailto
          gallery_hover_gif
          mailto_image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          hover_gradient_one {
            hex
          }
          hover_gradient_two {
            hex
          }
          hover_gradient_three {
            hex
          }
        }
    }
    allSanityRole {
      nodes {
        name
        order
      }
    }
    imageData: allSanityImageAsset {
      nodes {
        fluid(maxWidth: 400) {
          ...GatsbySanityImageFluid_noBase64
        }
        id
      }
    }
}
  
`