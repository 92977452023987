import React from 'react'

import styled from 'styled-components';
import Axios from 'axios';

import {device} from '../styles/Breakpoints';


class IndexShop extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            propProducts: [],
            allProducts: []
        };
    }

    componentDidMount() {
        this.setState({ propProducts: this.props.shopData.products })
        this.getProductData();
        // console.log('SHOP DATA', this.props.shopData)
    }

    getProductData = () => {
        Axios.get('https://api.bigcartel.com/imogenwilson/products.json').then((response) => {
            // console.log('BIGCARTEL', response)
            const responseProducts = response.data
            //TODO - Only get products that match propProducts
            this.setState({ allProducts: response.data })
          }).catch(function (error) {
          console.log('BigCartel ', error);
          console.log('BigCartel API Error: ', error.response.data.reason)
          })
          .then(function () {
            // always executed
          }); 
    }

    render() {

        // const sectionMinHeight = this.state.allProducts.length ? '100vh' : '25vh';

        const ShopStyles = styled.section`
            /* border: 1px solid purple; */
            width: 100vw;
            min-height: 25vh;
            h3 {
               font-size: 36px; 
               font-weight: 700;
               text-align: center;
               margin: 48px 0;
            }
            .shop-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }
            .product {
                &__tile {
                    width: calc(100% / 3);
                    padding: 0 12px;
                    margin-bottom: 48px;
                    cursor: pointer;
                    transition: .2s all ease-in-out;
                    @media ${device.$small} {
                        width: 50%;
                    }   
                    &:hover {
                        transform: scale(1.03)
                    }
                }
                &__image {
                    width: 100%;
                    position: relative;
                    margin-bottom: 12px;
                    img {
                        max-width: 100%;
                        margin: 0 auto;
                        display: block;
                    }
                }
                &__details {
                    text-align: center;
                    font-weight: bold;
                }
                &__title {
                    font-size: 24px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
                &__price {
                    font-size: 18px;
                    font-weight: 400;
                    margin-top: 6px;
                }
            }
        `

        return(
            <ShopStyles className="container">
                <h3>SHOP</h3>
                <div className="shop-container">
                { 
                    this.state.allProducts.map(product => 

                    <a href={`https://imogenwilson.bigcartel.com${product.url}`} target="_blank" rel="noopener" className="product__tile" key={product.id}>
                        <div className="product__image">
                            <img src={product.images[0].url + '?w=400'} alt={product.name}/>
                        </div>
                        <div className="product__details">
                            <div className="product__title">
                                {product.name}
                            </div>
                            <div className="product__price">
                                ${product.price}
                            </div>
                        </div>
                    </a>
                    
                    ) 
                }
                </div>
            </ShopStyles>
        )
    }
}

export default IndexShop