import React from 'react'
import Masonry from 'react-masonry-css'


import styled from 'styled-components';
import {device} from '../styles/Breakpoints'
import GalleryTile from './GalleryTile';

const FeaturedGalleryStyles = styled.section`
        /* border: 1px solid green; */
        padding-left: 12vw;
        padding-right: 12vw;
        padding-bottom: 0;
        .feat-gallery {
            transition: .2s all ease-in-out;
            &:hover {
                filter: invert(1);
            }
        }
        &.with-marquee {
            padding-top: calc(var(--header-height-marquee) - 24px);
            @media ${device.$small} {
                /* padding-top: 96px; */
                padding-top: var(--header-height-mobile);
            }
        }
        &.no-marquee {
            padding-top: var(--header-height);
            @media ${device.$small} {
                /* padding-top: 96px; */
                padding-top: var(--header-height-mobile);
            }
        }
        .masonry-grid__column {
        padding-left: 130px; /* gutter size */
        }
        .masonry-grid {
        margin-left: -130px; /* gutter size offset */
        }
        .masonry-grid__column > div { 
        margin-bottom: 130px;
        }
        @media ${device.$small} {
            padding: 96px 12px 0;
            .masonry-grid__column {
                padding-left: 12px; /* gutter size */
            }
            .masonry-grid {
                margin-left: -12px; /* gutter size offset */
            }
            .masonry-grid__column > div { 
                margin-bottom: 12px;
            }
        }
`

// const test = () => {
//     const gridColumns = document.getElementsByClassName('masonry-grid__column');
//     const gridColumnsArray = Array.from(gridColumns);

//     const indexOfLongestColumn = gridColumnsArray.reduce((maxI,el,i,arr) => 
//     (el.length>arr[maxI].length) ? i : maxI, 0);

//     if (indexOfLongestColumn == 0) {
//         const longestColumn = gridColumnsArray[indexOfLongestColumn];
//         console.log('longneck: ', gridColumnsArray[0])
//         const nextColumnAlong = longestColumn.nextSibling;
//         const extraGridItem = longestColumn.lastChild;
//         nextColumnAlong.appendChild(extraGridItem);
//     }

// }

export default function IndexFeatured(props) {
    const data = props.galleries;
    // console.log('GALLERY PROPS', props.galleries)
    const featuredGalleries = [];
    const className = props.siteSettings.marquee_text ? 'container with-marquee' : 'container no-marquee';
    data.forEach(gallery => {
        featuredGalleries.push(
        // <FeaturedGallery galleryProps={gallery} key={gallery.name}/>
        <GalleryTile galleryProps={gallery} galleryHoverGif={props.galleryHoverGif} key={gallery.name}/>
        )
    });

    // test();
    return (
        <FeaturedGalleryStyles className={className}>
            <Masonry
                breakpointCols={{ default: 3, 375: 2 }}
                className="masonry-grid"
                columnClassName="masonry-grid__column">
                {featuredGalleries}
            </Masonry>
        </FeaturedGalleryStyles>
    )
}