import React from 'react'

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

import LazyLoad from "vanilla-lazyload";
import lazyloadConfig from "../config/lazyload";

const VideoBannerStyles = styled.section`
        width: 100vw;
        max-height: 100vh;
        position: relative;
        overflow: hidden;
        margin-bottom: var(--double-gutter);
        @media ${device.$small} {
            max-height: initial;
            min-height: initial;
        }
        .bg-vid__container {
            position: relative;
            &--desktop {
                @media ${device.$small} {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                height: 100vh;
                @media ${device.$small} {
                    display: block;
                }
            }
            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .vimeo-embed {
            width: 100%;
            pointer-events: none;
            &--desktop {
                @media ${device.$small} {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                max-height: 100vh;
                @media ${device.$small} {
                    display: block;
                }
            }
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        mux-player {
            --controls: none;
        }
`


// function ImageOrVideoMobile(props) {
//     const data = props.data.videoBannerData;
//     const vimeoIframe = () => {
//         return { __html: data.mobile_vimeo_url }
//     }
//     if (data.enable_mobile_video) {
//         if (data.mobile_video) {
//             return <div className="bg-vid__container bg-vid__container--mobile" >
//                 <video playsInline autoPlay muted loop id="bg-Vid">
//                     <source src={data.mobile_video.video_file.asset.url} type={data.mobile_video.video_file.asset.mimeType} ></source>
//                 </video>
//             </div>
//         }
//         else {
//             return <div className="vimeo-embed vimeo-embed--mobile" dangerouslySetInnerHTML={vimeoIframe()}></div>
//         }
        
//     }
// }

// function VideoElement(props) {
    
//     const data = props.data.videoBannerData;
//     // console.log('IMGORVID', props)
//     const mobileElement = ImageOrVideoMobile(props)
//     const vimeoIframe = () => {
//         return { __html: data.desktop_vimeo_url }
//     }
    
//     if (data.desktop_video) {
//         return <>
//                 <VideoBannerStyles>
//                     <div className="bg-vid__container bg-vid__container--desktop" >
//                        <video playsInline autoPlay muted loop id="bg-Vid">
//                            <source src={data.desktop_video.video_file.asset.url} type={data.desktop_video.video_file.asset.mimeType} ></source>
//                        </video>
//                    </div>
//                    { mobileElement }
//                 </VideoBannerStyles>
//             </>

//     }
//     else if ( data.desktop_vimeo_url ) {
//         return  <>
//                 <VideoBannerStyles>
//                     <div className="vimeo-embed vimeo-embed--desktop" dangerouslySetInnerHTML={vimeoIframe()}></div> 
//                     { mobileElement }
//                 </VideoBannerStyles>
                
//                 </>
//     }
//     else {
//         return <><p className="no-video-set"></p></>
//     }
// }



// export default function IndexVideoBanner(props) {
//     // console.log('Vid Banner Props: ', props)
//     return (
//         <VideoElement data={props}/>
//     )
// }



export class IndexVideoBanner extends React.Component {

    componentDidMount() {
        // if (!document.lazyLoadInstance) {
        //     document.lazyLoadInstance = new LazyLoad({elements_selector: ".lazy", threshold: 600});
        // }
        // document.lazyLoadInstance.update();
      }
    
      componentDidUpdate() {
        // document.lazyLoadInstance.update();
      }


    VideoElement = (props) => {
    
        const data = props;
        // console.log('IMGORVID', props)
        const mobileElement = this.ImageOrVideoMobile(props)
        const vimeoIframe = () => {
            return { __html: data.desktop_vimeo_url }
        }
        
        if (data.desktop_video) {
            if (data.desktop_video.mux_video?.asset) {
                return (
                    <VideoBannerStyles>
                        <div className="bg-vid__container bg-vid__container--desktop" >
                            <mux-player
                                stream-type="on-demand"
                                playback-id={data.desktop_video.mux_video.asset.playbackId}
                                metadata-video-title="Test VOD"
                                metadata-viewer-user-id="user-id-007"
                                autoPlay
                                muted
                                loop
                            ></mux-player>
                        </div>
                        { mobileElement }
                    </VideoBannerStyles>
                )
            }
            else {
                return <>
                <VideoBannerStyles>
                    <div className="bg-vid__container bg-vid__container--desktop" >
                       <video playsInline autoPlay muted loop id="bg-Vid" className="lazy">
                           <source src={data.desktop_video.video_file.asset.url} type={data.desktop_video.video_file.asset.mimeType} ></source>
                       </video>
                   </div>
                   { mobileElement }
                </VideoBannerStyles>
            </>
            }
            
    
        }
        else if ( data.desktop_vimeo_url ) {
            return  <>
                    <VideoBannerStyles>
                        <div className="vimeo-embed vimeo-embed--desktop" dangerouslySetInnerHTML={vimeoIframe()}></div> 
                        { mobileElement }
                    </VideoBannerStyles>
                    
                    </>
        }
        else {
            return <><p className="no-video-set"></p></>
        }
    }

    ImageOrVideoMobile = (props) => {
        const data = props;
        const vimeoIframe = () => {
            return { __html: data.mobile_vimeo_url }
        }
        if (data.enable_mobile_video) {
            if (data.mobile_video) {
                return <div className="bg-vid__container bg-vid__container--mobile" >
                    <video playsInline autoPlay muted loop id="bg-Vid" className="lazy">
                        <source src={data.mobile_video.video_file.asset.url} type={data.mobile_video.video_file.asset.mimeType} ></source>
                    </video>
                </div>
            }
            else {
                return <div className="vimeo-embed vimeo-embed--mobile" dangerouslySetInnerHTML={vimeoIframe()}></div>
            }
            
        }
    }


    render() {
        return (
            this.VideoElement(this.props.videoBannerData)
            // <VideoElement data={this.props.videoBannerData}/>
        )
    }
}

export default IndexVideoBanner;