import React from 'react'
import Img from 'gatsby-image';

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

import { Parallax } from 'react-parallax';

import {SplashStyles, StaticImageBackground} from '../styles/components/indexSplashStyles';


function SplashLogo(props) {
    // console.log('THESE PROPS', props)
    const data = props.data
    const splashLogo = data.data.splash_logo
    if (splashLogo) {
        if (splashLogo.asset.extension == 'svg') {
            return <div className="splash-logo">
                <img className={ 'splash-image__logo' } src={splashLogo.asset.url} alt={'logo'}/>
            </div>
            
        }
        else {
            return <div className="splash-logo">
                <Img className={ 'splash-image__logo' } fluid={splashLogo.asset.fluid} alt={'logo'} loading="eager" />
            </div>
        }
    }
    else {
        if (data.logo.asset.extension == 'svg') {
            return <div className="splash-logo">
                <img className={ 'splash-image__logo' } src={data.logo.asset.url} alt={'logo'}/>
            </div>
            
        }
        else {
            return <div className="splash-logo">
                <Img className={ 'splash-image__logo' } fluid={data.logo.asset.fluid} alt={'logo'} loading={'eager'}/>
            </div>
        }
        
    }
    
}

function ImageOrVideoMobile(props) {
    const data = props.data.data;
    if (data.enable_mobile_video && data.enable_desktop_video) {
        if (data.mobile_video.mux_video) {
            return <div className="bg-vid__container bg-vid__container--mobile" >
                <mux-player
                    stream-type="on-demand"
                    playback-id={data.mobile_video.mux_video.asset.playbackId}
                    metadata-video-title="Test VOD"
                    metadata-viewer-user-id="user-id-007"
                    autoPlay
                    muted
                    loop
                ></mux-player>
            </div>
        }
        else {
            return <div className="bg-vid__container bg-vid__container--mobile" >
            <video playsInline autoPlay muted loop id="bg-Vid">
                <source src={data.mobile_video.video_file.asset.url} type={data.mobile_video.video_file.asset.mimeType} ></source>
            </video>
        </div>
        }
        
    }
    else {
        return <Img className={ 'splash-image__wrapper splash-image__wrapper--mobile' } fluid={data.mobile_image.asset.fluid} alt={'Splash image'}/>
    }
}

function ImageOrVideo(props) {
    
    const data = props.data.data;
    // console.log('IMGORVID', props)
    const mobileElement = ImageOrVideoMobile(props)
    
    if (data.enable_desktop_video) {
        if (data.desktop_video.mux_video) {
            return (
                <SplashStyles>
                    <div className="bg-vid__container bg-vid__container--desktop" >
                        <mux-player
                            stream-type="on-demand"
                            playback-id={data.desktop_video.mux_video.asset.playbackId}
                            metadata-video-title="Test VOD"
                            metadata-viewer-user-id="user-id-007"
                            autoPlay
                            muted
                            loop
                        ></mux-player>
                    </div>
                    { mobileElement }
                    <SplashLogo data={props.data}/>
                </SplashStyles>
            )
        }
        else {
            return <>
                <SplashStyles>
                    <div className="bg-vid__container bg-vid__container--desktop" >
                        <video playsInline autoPlay muted loop id="bg-Vid">
                            <source src={data.desktop_video.video_file.asset.url} type={data.desktop_video.video_file.asset.mimeType} ></source>
                        </video>
                    </div>
                    { mobileElement }
                    <SplashLogo data={props.data}/>
                </SplashStyles>
            </>
            
        }

    }
    else {
        return <StaticImageBackground>
                    <div className="background-image-wrapper">
                        <Img className={ 'splash-image__wrapper splash-image__wrapper--desktop' } fluid={data.desktop_image.asset.fluid} alt={'Splash image'}/>
                        { mobileElement }
                    </div>
                    <SplashLogo data={props.data}/>
                </StaticImageBackground>
        // return  <Parallax bgImage={data.desktop_image.asset.fluid.src} 
        //                     bgImageSrcSet={`${data.desktop_image.asset.fluid.src} 1920w, ${data.mobile_image.asset.fluid.src} 768w`}
        //                     bgImageSizes={'100vw'}
        //                     strength={400}
        //                     bgImageAlt={'splash image'}
        //                     >
        //             <SplashStyles>
        //                 <SplashLogo data={props.data}/>
        //             </SplashStyles>
        //         </Parallax>
    }
}



export default function IndexSplash(props) {
    // console.log('Splash Props: ', props)
    return (
        <ImageOrVideo data={props}/>
    )
}
