import React from 'react'
import Img from 'gatsby-image';

import styled from 'styled-components';

import {device} from '../styles/Breakpoints'

const DiaryStyles = styled.section`
        /* border: 1px solid green; */
        width: 100vw;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 24px;
        .image-test {
            position: absolute;
            /* border: 1px solid red; */
        }
        @media ${device.$small} { 
            margin-top: 48px;
        }
`


function ImageTest(props) {
    const data = props.data;
    const images = props.imageData;
    const planDimensions = data[0];
    const newArray = []
    for (let i = 1; i < data.length; i++) {
        const item = data[i];
        const leftPercentage = item.position.x / planDimensions.width * 100 + '%';
        const topPercentage = item.position.y / planDimensions.height * 100 + '%';
        const imageWidth = item.size.width / planDimensions.width * 100 + '%';
        const altText = `Diary Image ${i}`;
        let thisImage = images.find(selectedImage => {
            return selectedImage.id == item.id
          })
        newArray.push(
            <div className="image-test"
            key={thisImage.id}
            style={{
                top: topPercentage,
                left: leftPercentage,
                width: imageWidth,
                height: item.size.height,
                zIndex: item.zIndex
            }}
            >
            {/* <Img className="image-test"
            fluid={thisImage.fluid}
            alt={altText}
            /> */}
            <img className="image-test"
            src={thisImage.fluid.src}
            alt={altText}
            loading="lazy"
            />
            </div>
            
        )
    }
    return <>
        {newArray}
    </>
}

export default function IndexDiary(props) {
    const data = props.diarySectionData;
    const positionData = JSON.parse(data.diary_json);
    const aspectRatioPadding = positionData[0].height / positionData[0].width * 100;
    // console.log('DIARY DATA:', data)
    // console.log('posdata:', positionData)
    return (
        <DiaryStyles id="diary-section" style={{ paddingBottom: aspectRatioPadding + '%' }}>
            <ImageTest data={positionData} imageData={props.imageData} />
        </DiaryStyles>
    )
}