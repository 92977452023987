import React from 'react'

import { Link } from 'gatsby';

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

const SecondaryMenuStyles = styled.section`
        width: 100vw;
        padding: 48px 24px;
        min-height: 170px;
        font-size: 26px;
        font-weight: 900;
        text-transform: uppercase;
        .menu-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            @media ${device.$small} {
                flex-direction: column;
            }
        }
        .menu-item {
            &:hover {
                background: -webkit-linear-gradient(1turn, ${props => props.siteSettings.hover_gradient_one.hex}, ${props => props.siteSettings.hover_gradient_two.hex}, ${props => props.siteSettings.hover_gradient_three.hex});
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &:not(:last-child) {
                margin-right: 48px;
                @media ${device.$small} {
                    margin-right: 0;
                }
            }
            @media ${device.$small} {
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
        .mobile-break {
            display: none;
            flex-basis: 100%;
            @media ${device.$small} {
                display: block;
            }
        }
        .click-down {
            cursor: pointer;
            margin: 0 auto 48px;
            width: 48px;
            height: 48px;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevrons-down'%3E%3Cpolyline points='7 13 12 18 17 13'%3E%3C/polyline%3E%3Cpolyline points='7 6 12 11 17 6'%3E%3C/polyline%3E%3C/svg%3E");
        }
`
const handleClick = () => {
    document.getElementById('diary-section').scrollIntoView({
        behavior: "smooth",
        block: "nearest"
    })
}


export default function IndexSecondaryMenu(props) {
    const data = props.siteSettings;
    return (
        <SecondaryMenuStyles siteSettings={data}>
            <div className="click-down" onClick={handleClick}></div>
            <div className="menu-container">
                <Link to="/galleries?filter=Photos" className="menu-item">Photos</Link>
                <Link to="/galleries?filter=Styling" className="menu-item">Styling</Link>
                <div className="mobile-break"></div>
                <Link to="/galleries?filter=Casting" className="menu-item">Casting</Link>
                <a href={data.shop_url} rel="noopener" target="_blank" className="menu-item">Shop</a>
            </div>
            
        </SecondaryMenuStyles>
    )
}
